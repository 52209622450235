<template>
  <button :color="colors[color_index]"
          :gradient="'linear-gradient(90deg, ' + colors[color_index] + ' 0%, ' + gradients[color_index] + ' 100%)'"
          :type="'button'"><span v-if="icon">{{ icon }}&nbsp;</span>{{ value }}</button>
</template>

<script>
export default {
  name: "GradientButton",
  props: ['color_index', 'icon', 'value'],
  data: () => ({
    colors: ['#01EAEF', '#00C0F9', '#285BFE', '#931CFF', '#642AB5'],
    gradients: ['#18E48F', '#01EAEF', '#00C0F9', '#642AB5', '#285BFE']
  })
}
</script>

<style scoped>
  span {
    font-family: orion-font,emoji;
    font-size: 20px;
    line-height: 22px;
  }
  button {
    font-family: Inter,sans-serif;
    margin: 1em!important;
    z-index: 0!important;
  }
</style>
