<template>
    <textarea
        class="ant-input ant-input-lg"
        cols="80"
        :id="id"
        :placeholder="placeholder"
        readonly="readonly"
        :value="value"/>
</template>

<script>
export default {
  name: "ReadonlyTextarea",
  props: ['id', 'value', 'placeholder']
}
</script>

<style scoped>
  textarea {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background-color: #272935!important;
    color: #FFFFFF;
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
    font-size: 1em;
    line-height: 2em;
    resize: none;
    width: 100%;
  }
</style>
