<template>
  <GradientButton
      class="ant-btn ant-btn-primary ant-btn-lg"
      :color_index="2"
      value="Connect Wallet"
      @click="connect"/>
</template>

<script>
import Onboard from '@web3-onboard/core'
import injectedModule from '@web3-onboard/injected-wallets'
import coinbaseModule from '@web3-onboard/coinbase'
import ledgerModule from '@web3-onboard/ledger'
import trezorModule from '@web3-onboard/trezor'
import keepkeyModule from '@web3-onboard/keepkey'
import GradientButton from "@/components/GradientButton";

export default {
  name: "ConnectWalletButton",
  components: {GradientButton},
  emits: ['set-wallet-id', 'set-provider', 'set-accounts', 'set-unsubscribe'],
  data: () => ({
    onboard: null,
    state: null,
    unsubscribe: null,
    wallet: null,
    accounts: null,
  }),
  mounted () {
    const injected = injectedModule()
    const coinbase = coinbaseModule()
    const ledger = ledgerModule()
    const trezor = trezorModule({
      email: '<EMAIL_CONTACT>',
      appUrl: '<APP_URL>'
    })
    const keepkey = keepkeyModule()

    this.onboard = Onboard({
      wallets: [
        injected,
        coinbase,
        ledger,
        trezor,
        keepkey
      ],
      chains: [
        {
          id: '0x1',  // chain ID must be in hexadecimal
          token: 'ETH',  // main chain token
          label: 'Ethereum Mainnet',
          rpcUrl: process.env.VUE_APP_MAINNET_RPC_URL  // rpcURL required for wallet balances
        },
      ],
      appMetadata: {
        name: 'Aleo Discord Role Verifier',
        icon: '/favicon.svg',
        logo: './favicon.svg',
        description: 'Capture proof for confirming participation in Aleo Testnet',
        recommendedInjectedWallets: [
          {name: 'Coinbase', url: 'https://wallet.coinbase.com/'},
          {name: 'MetaMask', url: 'https://metamask.io'},
          {name: 'Ledger', url: 'https://www.ledger.com'},
          {name: 'Trezor', url: 'https://trezor.io'},
          {name: 'KeepKey', url: 'https://shapeshift.com/keepkey'}
        ]
      }
    })
  },
  watch: {
    // whenever onboard.state changes, this function will run
    state(state) {
      if (!state.length) {
        return
      }
      if (typeof state[0].label !== 'undefined') {
        this.$emit('set-wallet-id', state[0].label)
      }
      if (typeof state[0].provider !== 'undefined') {
        this.$emit('set-provider', state[0].provider)
      }
      if (typeof state[0].accounts !== 'undefined') {
        this.$emit('set-accounts', state[0].accounts)
      }
    }
  },
  methods: {
    async connect () {
      this.wallet = await this.onboard.connectWallet()
      const state = this.onboard.state.select('wallets')
      const { unsubscribe } = state.subscribe(update => this.state = update)
      this.unsubscribe = unsubscribe
      this.$emit('set-unsubscribe', this.unsubscribe)
      if (!this.wallet.length) {
        return
      }
      if (typeof this.wallet[0].label !== 'undefined') {
        this.$emit('set-wallet-id', this.wallet[0].label)
      }
      if (typeof this.wallet[0].provider !== 'undefined') {
        this.$emit('set-provider', this.wallet[0].provider)
      }
      if (typeof this.wallet[0].accounts !== 'undefined') {
        this.accounts = this.wallet[0].accounts
        this.$emit('set-accounts', this.accounts)
      }
    },
  }
}
</script>

<style scoped>
  button {
    -moz-osx-font-smoothing: grayscale;
    -webkit-appearance: button;
    -webkit-font-smoothing: antialiased;
    clear-after: both;
    display: inline-block;
    float: right;
  }
</style>
