<template>
  <div class="row">
    <input type="radio"
           :id="'account-' + this.delta"
           :value="this.account.address"
           v-model="address"
           @click="$emit('set-address', this.account.address)"
    />
    <label :for="'account-' + this.delta">{{this.account.address}}</label>
  </div>

</template>

<script>
export default {
  name: "AddressRadio",
  props: ['delta', 'account'],
  emits: ['set-address'],
  data: () => ({
    address: '',
  })
}
</script>

<style scoped>
  input[type="radio"] {
    accent-color: #285BFE;
    background-color: #FFFFFF;
    border: 1px solid #285BFE;
    border-radius: 100%;
    color: #1E2029;
    cursor: pointer;
    font-size: .95em;
    line-height: .95em;
    margin-right: .2em;
    width: 1em;
    vertical-align: middle;
  }
  label {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: #1E2029;
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
    font-size: .95em;
    line-height: .95em;
    vertical-align: middle;
  }
</style>
