<template>
  <div class="wrapper">
    <header>
      <ConnectWalletButton
          @set-wallet-id="this.setWalletId"
          @set-accounts="this.setAccounts"
          @set-provider="this.setProvider"
          @set-unsubscribe="this.setUnsubscribe"
          :class="{'invisible': this.wallet_id !== ''}"
      />
    </header>
    <main>
      <section>
        <div class="content">
          <div class="row">
            <img src="./assets/logo.png" alt="[Aleo Lambda] Aleo Logo" class="logo">
          </div>
          <div class="row">
            <SignButton
                @sign-payload="this.setSignature"
                :provider="this.provider"
                :msg="this.message"
                :wallet_id="this.wallet_id"
                :account="this.account"
                :class="{'invisible': this.account === '' || this.signature_json !== ''}"/>
          </div>
          <div :class="{'row': true, 'invisible': this.message === ''}">
            <h2>Verification Message</h2>
            <p>
              This message contains public information, which you will be verifying by signing it with your private key.
            </p>
            <ReadonlyTextarea
                :id="'message'"
                :value="this.message"
                :placeholder="'This input will contain a JSON representation of the values which you will be signing ' +
                'to verify account ownership.'"
                style="height: 6em!important;"/>
          </div>
          <div :class="{'row': true, 'invisible': this.message === '' || this.wallet_id !== 'Ledger'}">
            <h2>SHA256 Hash</h2>
            <p>Some hardware wallets like ledger show the message to be signed hashed as a SHA256 hash.
              You can verify this by encoding the above verification message with SHA256 using an online encoder tool.
              It should give the same output as below:
            </p>
            <label>SHA256 message shown before accepting to sign the message on the Ledger Wallet</label>
            <ReadonlyTextarea
                :id="'message_hash_sha256'"
                :value="this.message_hash_sha256"
                :placeholder="'This input will contain a SHA-256 hash of the above verification message hex digest ' +
                 'shown as a preview.'"
                style="height: 12em!important;"/>
          </div>
          <div class="row">
            <h2>Signed Payload</h2>
            <p>Connect Your Wallet, and click the Sign Button which appears.
              Confirm in your wallet, then come back to this screen.</p>
            <p>
              When a JSON message appears here, send it to the discord verification bot using the Copy Button which
              appears.
              <br/><br/>
              The JSON message below contains everything needed to complete the verification process.
            </p>
            <ol>
              <li>
                <span>
                  <code>signature</code> The signature your wallet generated by signing the <code>payload</code>
                </span>
              </li>
              <li>
                <span>
                  <code>payload</code> The verification JSON message shown after choosing an account in your wallet.
                </span>
              </li>
            </ol>
            <ReadonlyTextarea
                :id="'signature'"
                :value="this.signature_json"
                :placeholder="'This textarea will contain the signed verification which you should copy to discord.'"
                style="height: 12em!important;"/>
          </div>
          <div class="row">
            <CopyButton :signature="this.signature_json" :class="{'invisible': this.signature_json === ''}"/>
          </div>
        </div>
      </section>
    </main>
    <footer/>
  </div>
  <div id="modals"  :class="{'vue-universal-modal': true, 'invisible': !isShow}">
    <AddressPicker
        v-model="isShow"
        :close="closeModal"
        :disabled="!isShow"
        :accounts="this.accounts"
        @address="this.setAccount"/>
  </div>
</template>

<script>
import ConnectWalletButton from './components/ConnectWalletButton.vue'
import ReadonlyTextarea from './components/ReadonlyTextarea.vue'
import AddressPicker from './components/AddressPicker.vue'
import SignButton from './components/SignButton.vue'
import CopyButton from './components/CopyButton.vue'
import Web3 from 'web3'
import shajs from 'sha.js'

export default {
  name: 'App',
  components: {
    ConnectWalletButton,
    ReadonlyTextarea,
    AddressPicker,
    SignButton,
    CopyButton
  },
  data: () => ({
    name: '',
    discriminator: '',
    wallet_id: '',
    accounts: [],
    account: '',
    message: '',
    message_hex: '',
    message_hash_sha256: '',
    provider: {},
    isShow: false,
    signature: {},
    signature_json: '',
    unsubscribe: null,
  }),
  watch: {
    '$route.query': {
      handler: function(query) {
        if (typeof query['discriminator'] !== 'undefined') {
          this.discriminator = decodeURIComponent(query['discriminator'])
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    setWalletId: function(wallet_id) {
      this.wallet_id = wallet_id
    },
    setAccounts: function(accounts) {
      this.accounts = accounts
      this.showModal()
    },
    setAccount: function(address) {
      const web3 = new Web3(this.provider)
      this.account = web3.utils.toChecksumAddress(address)
      this.unsubscribe()
      this.setMessage()  // depends on this.account.
      this.closeModal()
    },
    setMessage: function() {
      // https://eips.ethereum.org/EIPS/eip-191
      const messageData = {
        from: this.account,
        discriminator: this.discriminator
      }
      this.message = JSON.stringify(messageData)
      this.setMessageHashSha256(this.message)
    },
    setMessageHex: function(message) {
      const web3 = new Web3(this.provider)
      this.message_hex = web3.utils.toHex(message)
    },
    setMessageHashSha256: function(message) {
      this.setMessageHex(message)
      this.message_hash_sha256 = new shajs.sha256().update(message).digest('hex').toUpperCase()
    },
    setProvider: function(provider) {
      this.provider = provider
    },
    showModal: function() {
      this.isShow = true
    },
    closeModal: function() {
      this.isShow = false
    },
    setSignature: function(signature) {
      this.signature = signature
      this.signature_json = JSON.stringify(signature)
    },
    setUnsubscribe: function(unsubscribe) {
      this.unsubscribe = unsubscribe
    }
  }
}
</script>

<style>
  html {
    background: #242424;
    position: relative;
    font-family: Inter,sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #app {
    background: #242424;
    color: #CDCDCD;
    text-align: center;
    z-index: 0;
    height: 100vh;
  }
  img.logo {
    width: 360px;
    height: auto ;
    max-width: 100% ;
  }
  #modals {
    height: 100vh;
    background-color: rgba(0, 0, 0, .5);
  }
  header {
    height: 6em;
    clear: both;
  }
  section {
    display: flex;
    justify-content: center;
  }
  section > div.content {
    vertical-align: middle;
    display: grid;
    max-width: 900px;
    grid-template-columns: 3fr;
  }
  .row {
    grid-column-start: 1;
    grid-column-end: 3;
    margin-bottom: 1em;
  }
  .invisible {
    display: none!important;
  }
  p {
    margin: auto auto 2em;
  }
  ol {
    width: 90vw;
  }
  li {
    text-align: left;
    line-height: 20px;
    margin-bottom: 1em;
  }
  code {
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
    background-color: #272935!important;
    padding: 2px 4px;
    border-radius: 3px;
    margin-bottom: 0!important;
    display: inline;
    line-height: 16px;
  }
  span {
    display: inline;
  }
  /*code {*/
  /*  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;*/
  /*  width: 100vw;*/
  /*  display: block;*/
  /*  margin: auto auto;*/
  /*}*/
</style>
