<template>
  <GradientButton
      :color_index="3"
      :icon="'&#9998;'"
      :value="'Sign Message'"
      :class="'ant-btn ant-btn-primary ant-btn-lg'"
      @click="sign"
  />
</template>

<script>
import Web3 from 'web3'
import GradientButton from "@/components/GradientButton";

export default {
  name: "SignButton",
  components: {GradientButton},
  props: ['msg', 'wallet_id', 'account', 'provider'],
  emits: ['sign-payload'],
  data: () => ({}),
  methods: {
    async sign() {
      // https://eips.ethereum.org/EIPS/eip-712
      const web3 = new Web3(this.provider)
      if (['Ledger', 'KeepKey', 'Trezor'].indexOf(this.wallet_id) !== -1) {
        const signature = await web3.eth.sign(this.msg, this.account)
        const sign_payload = {
          signature: signature,
          payload: JSON.parse(this.msg)
        }
        this.$emit('sign-payload', sign_payload)
      }
      else {
        const self = this
        await web3.currentProvider.sendAsync({
              jsonrpc: "2.0",
              method: "personal_sign",
              params: [this.msg, this.account],
            },
            function (err, result) {
              if (err) {
                return console.error(err);
              }
              const signature_result = result['result']
              const sign_payload = {
                signature: signature_result,
                payload: JSON.parse(self.msg)
              }
              self.$emit('sign-payload', sign_payload)
            });
      }
    }
  },
}
</script>

<style scoped>
  button {
    font-family: Inter,sans-serif;
  }
</style>
