import { createApp } from 'vue'
import App from './App.vue'
import 'vue-universal-modal/dist/index.css'
import VueUniversalModal from 'vue-universal-modal'
import { createRouter, createWebHistory } from 'vue-router'
import '@aleohq/ui/dist/blue.css';

const router = createRouter({
    history: createWebHistory(),
    mode: 'history',
    routes: [{path: '/', name: 'Home', component: App}]
});

createApp(App)
    .use(router)
    .use(VueUniversalModal, {
        teleportTarget: '#modals',
        modalComponent: 'AddressPicker',
    })
    .mount('#app')
