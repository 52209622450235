<template>
  <div :class="{'modal': true, 'vue-universal-modal-content': true, 'full': this.full}">
    <h3>Choose your Account</h3>
    <AddressRadio
        v-for="(account, delta) in this.accounts"
        :account="account"
        :delta="delta"
        :key="delta"
        @set-address="this.setAddress"
    />
    <GradientButton
        class="ant-btn ant-btn-primary ant-btn-md"
        :color_index="2"
        :disabled="this.address === ''"
        value="Submit"
        @click="$emit('address', this.address)"
    />
  </div>
</template>

<script>
import AddressRadio from './AddressRadio.vue'
import GradientButton from "@/components/GradientButton";

export default {
  name: "AddressPicker",
  props: ['accounts'],
  emits: ['address'],
  components: {
    GradientButton,
    AddressRadio,
  },
  data: () => ({
    address: '',
    full: false,
  }),
  methods: {
    setAddress: function(address) {
      this.address = address
    }
  }
}
</script>

<style scoped>
  .modal {
    background-color: #FFFFFF;
    border: 1px dashed #c2c;
    box-sizing: border-box;
    font-size: 14px;
    margin: 25vh auto;
    max-width: 100%;
    min-height: fit-content;
    padding: 1em;
    width: max-content;
  }
  button {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: Inter,sans-serif;
    margin-bottom: 2em;
  }
  h3 {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: #1E2029;
    font-family: Inter,sans-serif;
  }
</style>
