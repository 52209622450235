<template>
  <GradientButton
      class="ant-btn ant-btn-primary ant-btn-lg"
      :color_index="2"
      icon="&#x2398;"
      value="Copy"
      @click='copy(this.signature)'/>
</template>

<script>
import GradientButton from "@/components/GradientButton";
export default {
  name: "CopyButton",
  components: {GradientButton},
  props: ['signature'],
  methods: {
    'copy': async (signature) => {
      await navigator.clipboard.writeText(signature)
    }
  }
}
</script>

<style scoped></style>
